.footer {
  padding: 40px 0;
  background: $lightestGray;

  &__info {
    color: $mainColor;
    text-align: center;
    margin-bottom: 20px;

    @include mq(md) {
      text-align: left;
      margin-bottom: 0;
    }

    p {
      font-weight: 700;
      margin: 0 0 5px;
      text-transform: uppercase;
    }

    a {
      color: $mainColor;
      text-decoration: none;
    }
  }

  &__social {
    display: flex;
    justify-content: center;
    text-align: center;
    margin-bottom: 20px;

    @include mq(md) {
      margin-bottom: 0;
    }

    li {
      margin: 0 15px;
    }

    a {
      display: flex;
      border-radius: 50%;
      width: 40px;
      height: 40px;
      background: $mainColor;
      color: $white;
      opacity: .5;
      text-decoration: none;
      justify-content: center;
      align-items: center;

      i {
        color: $white;
      }

      &:hover {
        opacity: 1;
      }
    }
  }

  &__credits {
    text-align: center;

    @include mq(md) {
      text-align: right;
    }
  }
}
