.header {
    position: fixed;
    z-index: 10000;
    width: 100%;

    @include mq(md) {
      position: absolute;
    }

    $h: &;

    &__hamburguer {
        cursor: pointer;
        width: 25px;
        height: 25px;
        margin: auto;
        position: absolute;
        right: 20px;
        top: 20px;
        z-index: 5000;
        background: $white;
        padding: 3px;

        @include mq(md) {
          right: 40px;
          top: 40px;
        }

        span {
            background-color: $mainColor;
            border-radius: 2px;
            content: '';
            display: block;
            width: 100%;
            height: 2px;

            &:nth-child(1) {
                animation:outT 0.6s $easing backwards;
                animation-direction:reverse;
            }
            &:nth-child(2) {
                margin: 7px 0;
                animation:outM 0.6s $easing backwards;
                animation-direction:reverse;
            }
            &:nth-child(3) {
                animation:outBtm 0.6s $easing backwards;
                animation-direction:reverse;
            }
        }
    }

    &__tagline {
      position: absolute;
      top: 35px;
      color: $mainColor;
      padding-left: 5px;
      margin: 0;
      line-height: 1.5;
      font-size: 14px;
      left: 110px;
      font-weight: 300;

      @include mq(md) {
        top: 80px;
        font-size: 24px;
      }

      strong {
        font-weight: 700;
        display: inline-block;
      }

      span {
        background-color: $white;
        box-shadow: 0.5em 0 0 $white,-0.5em 0 0 $white;
      }
    }

    &__logo {
      position: absolute;
      width: 80px;
      left:15px;
      top: 10px;

      @include mq(md) {
        width: 180px;
        left: 0;
        right: 0;
        margin: auto;
        top: 40px;
      }

      img {
        width: 100%;
      }
    }

    &__nav {
      @include trans;

      position: fixed;
      right: 0;
      width: 300px;
      background: $white;
      padding: 30px;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      top: 0;
      bottom: 0;
      transform: translateX(100%);
    }

    &__menu {
      li {
        margin-bottom: 10px;
      }
      
      a {
        color: $white;
        text-transform: uppercase;
        font-family: $titleFont;
        font-size: 21px;
        text-decoration: none;

        span {
          background-color: $mainColor;
          box-shadow: 0.5em 0 0 $mainColor,-0.5em 0 0 $mainColor;
        }
      }
    }

    &.-open {
        #{$h}__hamburguer {
            span:nth-child(1) {
                animation:inT 0.6s $easing forwards;
            }
            span:nth-child(2) {
                animation:inM 0.6s $easing forwards;
            }
            span:nth-child(3) {
                animation:inBtm 0.6s $easing forwards;
            }
        }

        #{$h}__nav {
          transform: translateX(0);
        }
    }
}

//hamburguer animations
@keyframes inM{
50%{transform:rotate(0deg);}
100%{transform:rotate(45deg);}
}

@keyframes outM{
50%{transform:rotate(0deg);}
100%{transform:rotate(45deg);}
}

@keyframes inT{
0%{transform: translateY(0px) rotate(0deg);}
50%{transform: translateY(9px) rotate(0deg);}
100%{transform: translateY(9px) rotate(135deg);}
}

@keyframes outT{
0%{transform: translateY(0px) rotate(0deg);}
50%{transform: translateY(9px) rotate(0deg);}
100%{transform: translateY(9px) rotate(135deg);}
}

@keyframes inBtm{
0%{transform: translateY(0px) rotate(0deg);}
50%{transform: translateY(-9px) rotate(0deg);}
100%{transform: translateY(-9px) rotate(135deg);}
}
@keyframes outBtm{
0%{transform: translateY(0px) rotate(0deg);}
50%{transform: translateY(-9px) rotate(0deg);}
100%{transform: translateY(-9px) rotate(135deg);}
}
