@mixin title($color: $mainColor) {
  color: $color;
  font-size: 18px;
  font-family: $titleFont;
  text-transform: uppercase;
  letter-spacing: 6px;
  font-weight: 300;
  margin: 0 0 40px;

  @include mq(md) {
    font-size: 24px;
  }
}

@mixin sectionPadd {
  padding: 40px 0;

  @include mq(md) {
    padding: 5% 0;
  }
}

.home {
  &__hero {
    height: 100vw;
    background: url('/images/home/hero.jpg') no-repeat center center / cover;
    padding: 20px 0;
    display: flex;
    align-items: flex-end;

    @include mq(md) {
      height: 90vh;
      padding: 5% 0;
    }

    &__slider {
      padding-bottom: 5%;

      .slick-dots {
        bottom: -30px;
        left: 0;
        right: auto;
        transform: translateX(-10px);

        li button:before {
          color: $mainColor;
        }
      }
    }

    &__slide {
      display: inline-flex !important;
      align-items: flex-end;
    }

    &__quote {
      font-size: 16px;
      margin: 0;
      line-height: 1.5;
      color: $mainColor;
      padding-left: 5px;

      strong {
        font-weight: 700;
      }

      span {
        background-color: $white;
        box-shadow: 0.5em 0 0 $white,-0.5em 0 0 $white;
      }

      @include mq(md) {
        font-size: 21px;
      }

      @include mq(lg) {
        font-size: 1.8vmax;
      }
    }
  }

  &__about {
    @include sectionPadd;
    text-align: center;

    &__title {
      @include title;
    }

    &__text {
      color: $midGray;
      margin: 0 0 15px;
      font-size: 16px;
    }
  }

  &__values {
    position: relative;
    min-height: 90vh;
    display: flex;
    align-items: center;
    padding-top: 45vh;

    @include mq(md) {
      padding-top: 0;
      min-height: 45vh;
    }

    &__bg {
      position: absolute;
      top:0;
      left:0;
      width: 100%;
    }

    &__image {
      height: 45vh;
      width: 100%;

      img {
        object-fit: cover;
        width: 100%;
        height: 100%;
      }
    }

    &__content {
      text-align: center;
      color: $white;
    }

    &__title {
      @include title($white);
    }

    &__text {
      width: 90%;
      margin: auto;
      line-height: 1.5;

      @include mq(md) {
        width: 70%;
      }
    }
  }

  &__culture {
    @include sectionPadd();

    background: $lightestGray;
    text-align: center;

    &__heading {
      img {
        margin-bottom: 40px;
      }
    }

    &__title {
      @include title;
    }

    &__list {
      li {
        position: relative;
        color: $mainColor;
        
        &:not(:last-child) {
          padding-bottom: 40px;

          &:before {
            width: 100px;
            height: 1px;
            background: lighten($mainColor, 50%);
            position: absolute;
            left: 50%;
            transform: translateX(-50%);
            bottom: 20px;
            content: '';
          }

          &:after {
            content: '';
            width: 10px;
            height: 10px;
            border-radius: 50%;
            border: 1px solid lighten($mainColor, 50%);
            background: $lightestGray;
            position: absolute;
            left: 50%;
            transform: translateX(-50%);
            bottom: 15px;
          }
        }
      }
    }
  }

  &__gallery {
    @include sectionPadd();
    text-align: center;

    &__heading {
      margin-bottom: 40px;
    }

    &__title {
      @include title($white);
      padding-left: 5px;

      span {
        background-color: $mainColor;
        box-shadow: 0.5em 0 0 $mainColor,-0.5em 0 0 $mainColor;
      }
    }

    &__view-more {
      text-decoration: none;
      background: none;
      border: 1px solid $mainColor;
      color: $mainColor;
      display: inline-block;
      padding: 1px 10px 1px 30px;
      margin-left: 20px;
      position: relative;

      &:after,
      &:before {
        content: '';
        width: 12px;
        position: absolute;
        left: 7px;
        top: 50%;
        height: 1px;
        background: $mainColor;
      }

      &:after {
        transform: rotate(90deg);
      }
    }

    &__image {
      padding-bottom: 60%;
      height: 0;
      width: 100%;
      position: relative;
      overflow: hidden;
      display: block;
      margin-bottom: 30px;

      img {
        position: absolute;
        width: 100%;
        top: 0;
        left: 0;
      }
    }
  }

  &__goals {
    @include sectionPadd();

    background: url('/images/home/goals-bg.jpg') no-repeat center center / cover;
  
    &__title {
      @include title($white);
      text-align: right;
      line-height: 1.5;

      span {
        background-color: $mainColor;
        box-shadow: 0.2em 0 0 $mainColor,-0.2em 0 0 $mainColor;
      }
    }

    &__text {
      padding-left: 5px;
      line-height: 1.5;
      font-size: 21px;
      margin: 0;

      span {
        background-color: $white;
        box-shadow: 0.2em 0 0 $white,-0.2em 0 0 $white;
      }
    }

    &__slider {
      padding-bottom: 10%;

      .slick-dots {
        bottom: -30px;
        left: 0;
        right: auto;
        transform: translateX(-10px);

        li button:before {
          color: $mainColor;
        }
      }
    }

    &__bubbles {
      background: $lightestGray;
      padding: 40px 0;
    }

    &__bubble {
      @include trans;
      width: 100%;
      padding-bottom: 100%;
      overflow: hidden;
      height: 0;
      position: relative;
      border-radius: 50%;
      filter: grayscale(100%);
      margin-bottom: 30px;
      text-align: center;

      &:hover {
        filter: grayscale(0);

        ._text {
          opacity: 1;
        }
      }

      ._img {

        position: absolute;
        top:50%;
        left:50%;
        transform: translate3d(-50%, -50%, 0);
        width: 100%;
        min-height: 100%;
      }

      ._text {
        @include trans;

        font-family: $titleFont;
        color: $mainColor;
        font-size: 18px;
        text-transform: uppercase;
        position: absolute;
        top:50%;
        left:50%;
        transform: translate3d(-50%, -50%, 0);
        z-index: 2;
        opacity: 0;
        line-height: 1.5;

        span {
          background-color: $white;
          box-shadow: 0.2em 0 0 $white,-0.2em 0 0 $white;
        }
      }
    }
  }

  &__lawn-tennis-banner {
    @include sectionPadd();

    background: url('/images/home/buenos-aires-lawn-tennis-club.jpg') no-repeat center center / cover;
    min-height: 45vh;
    display: flex;
    align-items: center;

    &__title {
      @include title($white);
      text-align: right;
      line-height: 1.5;

      span {
        background-color: $mainColor;
        box-shadow: 0.2em 0 0 $mainColor,-0.2em 0 0 $mainColor;
      }
    }

    &__subtitle {
      line-height: 1.5;
      color: $white;
      text-transform: uppercase;
      font-weight: 300;
      margin: 0 0 20px;

      span {
        background-color: $mainColor;
        box-shadow: 0.2em 0 0 $mainColor,-0.2em 0 0 $mainColor;
      }
    }

    &__text {
      color: $mainColor;
      line-height: 1.5;
      font-size: 18px;

      span {
        background-color: $white;
        box-shadow: 0.2em 0 0 $white,-0.2em 0 0 $white;
      }
    }
  }
}

.gallery {
  &__hero {
    height: 80vw;
    background: url('/images/home/hero.jpg') no-repeat center center / cover;
    padding: 20px 0;
    display: flex;
    align-items: flex-end;

    @include mq(md) {
      height: 70vh;
      padding: 5% 0;
    }

    &__title {
      @include title($white);
      text-align: center;
      line-height: 1.5;

      span {
        background-color: $mainColor;
        box-shadow: 0.2em 0 0 $mainColor,-0.2em 0 0 $mainColor;
      }
    }
  }

  &__body {
    @include sectionPadd();
  }
}
